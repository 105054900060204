import {
  NeoComponents,
  Header,
  Container,
  validate,
  isEmpty,
  validateAllFormFields,
  isSuperadmin
} from "@forcepoint/platform-utilityui"
import { coreStore } from "@forcepoint/platform-coreui"
import * as singleSpa from "single-spa"
import { useEffect, useState } from "react"
import {
  EDIT_PROFILE_CONFIG,
  EditUserReqObj,
  TIMEZONE_LIST,
  UserProfileForm
} from "../userprofile.model"
import { CODE_LIST } from "../../../common/AppConstants"
import { blockInvalidChar } from "../utils"
import { useAppDispatch } from "../../../common/state/app.state"
import userProfileService from "../service/userprofile.service"
import {
  getActionStatus,
  getUserById,
  getTotpStatus
} from "../state/userprofile.selector"
import { ActionState } from "../state/userprofile.state"

const INIT_USER_FORM_STATE = {
  firstName: { value: "", touched: false },
  lastName: { value: "", touched: false },
  secondaryEmail: { value: "", touched: false },
  mobile: { value: "", touched: false },
  netbiosDomain: { value: "", touched: false },
  SAMAccountName: { value: "", touched: false },
  userPrincipalName: { value: "", touched: false },
  timezone: { value: "", touched: false },
  objectGUID: { value: "", touched: false },
  customAttr1: { value: "", touched: false },
  customAttr2: { value: "", touched: false },
  countryCode: { value: "", touched: false }
}
const EditUserProfile = (props) => {
  const dispatchAction = useAppDispatch()

  const [userId, setUserId] = useState(null)

  const userById = getUserById()
  const actionStatus = getActionStatus()
  const totpStatus = getTotpStatus(userId)
  const [configureScope, setConfigureScope] = useState<boolean>(false)
  /**
   * To subscribe corestore
   */
  coreStore.subscribe(() => {
    const currentState = coreStore.getState()
    const isEmulated = sessionStorage.getItem("isEmulated")
    const id =
      isEmulated === null
        ? currentState?.userInfo?.sub
        : JSON.parse(sessionStorage.getItem("oldUserInfo"))?.sub
    setUserId(id)
    if (currentState.userInfo?.scope?.includes("idm.totp.ui")) {
      setConfigureScope(true)
    }
  })

  /**
   * To store user form object
   */
  const [userFormObj, setUserFormObj] = useState<UserProfileForm>(
    structuredClone(INIT_USER_FORM_STATE)
  )

  /**
   * To store user form errors
   */
  const [userFormErrors, setUserFormErrors] = useState<any>()

  /**
   * To store valid form
   */
  const [isValidForm, setIsValidForm] = useState<boolean>(false)

  /**
   * To store selected timezone
   */
  const [selectedTimezone, setSelectedTimezone] = useState<any>()

  /**
   * To store initial code
   */
  const [countryCode, setCountryCode] = useState({ text: "", value: "" })

  /**
   * To get country code error
   */
  const [countyCodeError, setCountyCodeError] = useState(false)

  useEffect(() => {
    if (userId) {
      dispatchAction(userProfileService.getUserById({ id: userId }))
      if (!isSuperadmin() && configureScope) {
        dispatchAction(userProfileService.getTOTPStatus({ id: userId }))
      }
    }
  }, [userId])

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("editProfilePrevUrl")
    }
  }, [])

  /**
   * Update form details
   * @param key
   * @param val
   */
  const updateDetails = (key, val) => {
    const tempUserFormObj = { ...userFormObj }
    tempUserFormObj[key].value = typeof val === "string" ? val.trimStart() : val
    tempUserFormObj[key].touched = true
    setUserFormObj(tempUserFormObj)
    const tempUserFormErrors = validate(userFormObj, EDIT_PROFILE_CONFIG)
    setIsValidForm(tempUserFormErrors.isValidForm)
    setUserFormErrors(tempUserFormErrors.errors)
  }

  /**
   * Update on touched
   * @param key
   */
  const updateOnTouched = (key) => {
    const tempUserFormObj = { ...userFormObj }
    tempUserFormObj[key].touched = true
    setUserFormObj(tempUserFormObj)
    const tempUserFormErrors = validate(userFormObj, EDIT_PROFILE_CONFIG)
    setIsValidForm(tempUserFormErrors.isValidForm)
    setUserFormErrors(tempUserFormErrors.errors)
  }

  /**
   * To get mobile values
   * @returns
   */
  function getMobileValue() {
    if (userFormObj?.mobile?.value.includes(" ")) {
      const obj = userFormObj?.mobile?.value.split(" ")
      if (isEmpty(obj[1])) {
        return ""
      }
      return Number(obj[1])
    } else {
      return userFormObj?.mobile?.value
    }
  }

  /**
   * Action btn call back method
   * @param type
   */
  const actionBtnCallback = (type) => {
    if (type === "configureMFA" || type === "reset") {
      singleSpa.navigateToUrl(`/editprofile/configureMFA`)
    }
  }

  /**
   * USer by id Obj
   */
  useEffect(() => {
    if (userById?.value && userById.actionStatus === "success") {
      const [countryCode, mobile] = userById?.value?.mobile
        ? userById.value.mobile.split(" ")
        : ["", ""]
      const tempUserFormObj: UserProfileForm = {
        firstName: { value: userById.value.firstName, touched: false },
        lastName: { value: userById.value.lastName, touched: false },
        secondaryEmail: {
          value: userById.value.secondaryEmail,
          touched: false
        },
        countryCode: {
          value: countryCode ? countryCode.trim() : "",
          touched: false
        },
        mobile: { value: mobile ? mobile.trim() : "", touched: false },
        netBiosDomain: {
          value: userById.value.netbiosDomain,
          touched: false
        },
        customAttr1: {
          value: userById.value.customAttribute1,
          touched: false
        },
        customAttr2: {
          value: userById.value.customAttribute2,
          touched: false
        },
        SAMAccountName: {
          value: userById.value.samAccountName,
          touched: false
        },
        userPrincipalName: {
          value: userById.value.userPrincipalName,
          touched: false
        },
        timezone: {
          value: userById.value.timezone,
          touched: false
        },
        objectGUID: {
          value: userById.value.objectGUID,
          touched: false
        }
      }
      setUserFormObj(tempUserFormObj)
      const timezoneValue: any = TIMEZONE_LIST.filter(
        (x) => x.utc === userById.value.timezone
      )
      setSelectedTimezone({
        text: timezoneValue?.text,
        value: timezoneValue?.utc
      })

      const cntCode = userById.value?.mobile?.split(" ")[0]
      if (cntCode) {
        const val = CODE_LIST.find((code) => code.value == cntCode)
        if (val) {
          const newObj = { ...val, text: val.text, value: val.value }
          setCountryCode(newObj)
        }
      } else {
        setCountryCode(null)
      }
      const tempUserFormErrors = validate(tempUserFormObj, EDIT_PROFILE_CONFIG)
      setUserFormErrors(tempUserFormErrors.errors)
    }
  }, [userById])

  const handleSubmit = () => {
    if (
      userFormObj?.mobile.value?.trim() !== "" &&
      userFormObj.countryCode.value?.trim() === ""
    ) {
      setCountyCodeError(true)
      return false
    }
    const tempUserFormErrors = validateAllFormFields(
      userFormObj,
      EDIT_PROFILE_CONFIG
    )
    setUserFormErrors(tempUserFormErrors.errors)
    setIsValidForm(tempUserFormErrors.isValidForm)
    if (!tempUserFormErrors.isValidForm) {
      return
    }

    const userReqObj: EditUserReqObj = {
      id: userById.value.id,
      firstName: userFormObj.firstName.value,
      lastName: userFormObj.lastName.value,
      secondaryEmail: userFormObj.secondaryEmail.value,
      mobile:
        `${userFormObj?.countryCode.value} ${userFormObj?.mobile.value}`.trim(),
      customAttribute1: userFormObj.customAttr1.value,
      customAttribute2: userFormObj.customAttr2.value,
      netbiosDomain: userFormObj.netBiosDomain.value,
      objectGUID: userFormObj.objectGUID.value,
      samAccountName: userFormObj.SAMAccountName.value,
      timezoneId: selectedTimezone?.value,
      userPrincipalName: userFormObj.userPrincipalName.value
    }

    dispatchAction(userProfileService.editUser(userReqObj))
  }
  return (
    <div className="app-section">
      <div className="main-container">
        <div style={{ flex: 1 }}>
          <Header
            pageTitle="Edit Profile"
            actionCallback={actionBtnCallback}
            actionList={
              totpStatus?.actionStatus === "success" && configureScope
                ? [
                    {
                      type: "secondary",
                      displayName: totpStatus?.value
                        ? "Reset MFA"
                        : "Configure MFA",
                      key: totpStatus?.value ? "reset" : "configureMFA"
                    }
                  ]
                : []
            }
          />
        </div>
      </div>
      <Container style={{ height: "calc(100% - 62px)", overflow: "auto" }}>
        <NeoComponents.NeoExpandableContainer>
          <NeoComponents.NeoCenterFlex
            style={{ width: "100%", height: "100%", flexGrow: 1 }}
          >
            <form className="common-form" noValidate={true}>
              {/* <StyleComponents.Title className="mb-4"></StyleComponents.Title> */}
              <div style={{ width: "60%" }}>
                <div className="hideInputMsg edit-input">
                  <NeoComponents.NeoInput
                    {...{
                      id: "firstName",
                      placeholder: "Enter First Name",
                      containerStyle: { color: "#29343D", width: "90%" },
                      type: "text",
                      disabled: true,
                      label: "First Name:*",
                      value: userFormObj?.firstName?.value,
                      showRequiredLabelIcon: true,
                      errorMessage: userFormErrors?.firstName,
                      tabIndex: 1
                    }}
                  />
                </div>
                <div className="hideInputMsg edit-input">
                  <NeoComponents.NeoInput
                    {...{
                      id: "lastName",
                      placeholder: "Enter Last Name",
                      containerStyle: { color: "#29343D", width: "90%" },
                      type: "text",
                      tabIndex: 2,
                      label: "Last Name:*",
                      value: userFormObj?.lastName?.value,
                      showRequiredLabelIcon: true,
                      errorMessage: userFormErrors?.lastName,
                      onChange: (event) =>
                        updateDetails("lastName", event.value),
                      onBlur: () => updateOnTouched("lastName")
                    }}
                  />
                </div>
                <div className="hideInputMsg edit-input">
                  <NeoComponents.NeoInput
                    {...{
                      id: "secondaryEmail",
                      placeholder: "Enter Secondary Email",
                      containerStyle: { color: "#29343D", width: "90%" },
                      type: "email",
                      label: "Secondary Email:",
                      tabIndex: 3,
                      value: userFormObj?.secondaryEmail?.value,
                      showRequiredLabelIcon: true,
                      errorMessage: userFormErrors?.secondaryEmail,
                      onChange: (event) =>
                        updateDetails("secondaryEmail", event.value),
                      onBlur: () => updateOnTouched("secondaryEmail")
                    }}
                  />
                </div>
                <div className="hideInputMsg edit-input">
                  <NeoComponents.NeoInput
                    {...{
                      id: "netBiosDomain",
                      placeholder: "Enter NetBios Domain",
                      containerStyle: { color: "#29343D", width: "90%" },
                      type: "text",
                      label: "NetBios Domain:",
                      tabIndex: 3,
                      value: userFormObj?.netBiosDomain?.value,
                      showRequiredLabelIcon: true,
                      errorMessage: userFormErrors?.netBiosDomain,
                      onChange: (event) =>
                        updateDetails("netBiosDomain", event.value),
                      onBlur: () => updateOnTouched("netBiosDomain")
                    }}
                  />
                </div>
                <div className="hideInputMsg edit-input">
                  <NeoComponents.NeoInput
                    {...{
                      id: "SAMAccountName",
                      placeholder: "Enter SAMAccountName",
                      containerStyle: { color: "#29343D", width: "90%" },
                      type: "text",
                      label: "SAMAccountName:",
                      tabIndex: 3,
                      value: userFormObj?.SAMAccountName?.value,
                      showRequiredLabelIcon: true,
                      errorMessage: userFormErrors?.SAMAccountName,
                      onChange: (event) =>
                        updateDetails("SAMAccountName", event.value),
                      onBlur: () => updateOnTouched("SAMAccountName")
                    }}
                  />
                </div>
                <div className="hideInputMsg edit-input">
                  <NeoComponents.NeoInput
                    {...{
                      id: "userPrincipalName",
                      placeholder: "Enter User Principal Name",
                      containerStyle: { color: "#29343D", width: "90%" },
                      type: "text",
                      label: "User Principal Name:",
                      tabIndex: 3,
                      value: userFormObj?.userPrincipalName?.value,
                      showRequiredLabelIcon: true,
                      errorMessage: userFormErrors?.userPrincipalName,
                      onChange: (event) =>
                        updateDetails("userPrincipalName", event.value),
                      onBlur: () => updateOnTouched("userPrincipalName")
                    }}
                  />
                </div>
                <div>
                  <label style={{ fontSize: "12px" }}>Timezone:</label>
                  <div
                    className="hideInputMsg edit-input timezone"
                    style={{
                      width: "95%"
                    }}
                  >
                    <NeoComponents.NeoSelectWithSearch
                      id={"timezone"}
                      placeholder="Timezone"
                      onChange={(event) => {
                        console.log({ event })

                        setSelectedTimezone(event)
                        updateDetails("timezone", event.value)
                      }}
                      tabIndex={4}
                      optionList={TIMEZONE_LIST.map((x) => ({
                        text: x.text,
                        value: x.utc
                      }))}
                      selectedOption={selectedTimezone}
                    />
                    {/* {selectedTimezoneError && (
                      <label
                        className="custom-label pt-2"
                        style={{ color: "#FF3C1C", height: "auto" }}
                      >
                        Please select Valid Timezone
                      </label>
                    )} */}
                  </div>
                </div>
                <div className="hideInputMsg edit-input">
                  <NeoComponents.NeoInput
                    {...{
                      id: "objectGUID",
                      placeholder: "Enter objectGUID",
                      containerStyle: { color: "#29343D", width: "90%" },
                      type: "text",
                      label: "objectGUID:",
                      tabIndex: 3,
                      value: userFormObj?.objectGUID?.value,
                      showRequiredLabelIcon: true,
                      errorMessage: userFormErrors?.objectGUID,
                      onChange: (event) =>
                        updateDetails("objectGUID", event.value),
                      onBlur: () => updateOnTouched("objectGUID")
                    }}
                  />
                </div>
                <div className="hideInputMsg edit-input">
                  <NeoComponents.NeoInput
                    {...{
                      id: "customAttr1",
                      placeholder: "Enter Custom Attribute 1",
                      containerStyle: { color: "#29343D", width: "90%" },
                      type: "text",
                      label: "Custom Attribute 1:",
                      tabIndex: 3,
                      value: userFormObj?.customAttr1?.value,
                      showRequiredLabelIcon: true,
                      errorMessage: userFormErrors?.customAttr1,
                      onChange: (event) =>
                        updateDetails("customAttr1", event.value),
                      onBlur: () => updateOnTouched("customAttr1")
                    }}
                  />
                </div>
                <div className="hideInputMsg edit-input">
                  <NeoComponents.NeoInput
                    {...{
                      id: "customAttr2",
                      placeholder: "Enter Custom Attribute 2",
                      containerStyle: { color: "#29343D", width: "90%" },
                      type: "text",
                      label: "Custom Attribute 2:",
                      tabIndex: 3,
                      value: userFormObj?.customAttr2?.value,
                      showRequiredLabelIcon: true,
                      errorMessage: userFormErrors?.customAttr2,
                      onChange: (event) =>
                        updateDetails("customAttr2", event.value),
                      onBlur: () => updateOnTouched("customAttr2")
                    }}
                  />
                </div>
                <div>
                  <label style={{ fontSize: "12px" }}>Mobile:</label>
                  <div
                    className="hideInputMsg edit-input hide-number-arrow"
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      gap: "10px",
                      width: "90%",
                      flexDirection: "row"
                    }}
                  >
                    <div
                      className="mobile-county-code"
                      style={{ width: "120px" }}
                    >
                      <NeoComponents.NeoSelectWithSearch
                        id={"country-code"}
                        placeholder="Country Code"
                        onChange={(event) => {
                          const newObj = {
                            text: event.text,
                            value: event.value
                          }
                          setCountryCode(newObj)
                          updateDetails("countryCode", event.value)
                          setCountyCodeError(event.value === "")
                        }}
                        tabIndex={4}
                        optionList={CODE_LIST}
                        selectedOption={countryCode}
                      />
                      {countyCodeError && (
                        <label
                          className="custom-label pt-2"
                          style={{ color: "#FF3C1C", height: "auto" }}
                        >
                          Please select county code
                        </label>
                      )}
                    </div>
                    <NeoComponents.NeoInput
                      {...{
                        id: "mobile",
                        placeholder: "Enter Mobile",
                        containerStyle: {
                          color: "#29343D",
                          marginTop: "-16px",
                          width: "100%"
                        },
                        type: "number",
                        label: null,
                        tabIndex: 5,
                        value: getMobileValue(),
                        showRequiredLabelIcon: false,
                        errorMessage: userFormErrors?.mobile,
                        onChange: (event) =>
                          updateDetails("mobile", event.value),
                        onBlur: () => updateOnTouched("mobile"),
                        onKeyDown: (e) => blockInvalidChar(e),
                        onWheel: (e) => e?.target?.blur()
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="editprofile-footer">
                <NeoComponents.NeoSecondaryButton
                  id="up_cancel_btn"
                  size="small"
                  onClick={() => {
                    const url = sessionStorage.getItem("editProfilePrevUrl")
                    singleSpa.navigateToUrl(url)
                  }}
                >
                  Cancel
                </NeoComponents.NeoSecondaryButton>
                <NeoComponents.NeoPrimaryButton
                  id="up_save_btn"
                  size="small"
                  isLoading={
                    actionStatus[ActionState.EDIT_USER]?.actionStatus ===
                    "pending"
                  }
                  isDisabled={!isValidForm}
                  onClick={handleSubmit}
                >
                  Save
                </NeoComponents.NeoPrimaryButton>
              </div>
            </form>
          </NeoComponents.NeoCenterFlex>
        </NeoComponents.NeoExpandableContainer>
      </Container>
    </div>
  )
}

export default EditUserProfile
