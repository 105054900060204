import { useEffect, useState } from "react"
import * as singleSpa from "single-spa"
import ChangePasswordPopup from "./components/ChangePasswordModal"
import { useIdleTimer } from "react-idle-timer"
import {
  NeoComponents,
  FoneAlert,
  calcScopePermission
} from "@forcepoint/platform-utilityui"
import {
  coreStore,
  APP_LOGOUT,
  APP_URL,
  APP_TENANT,
  SET_ACTIVE_APP,
  getEnvionment
} from "@forcepoint/platform-coreui"
import ProfileInfoModal from "./components/ProfileInfoModal"
import AlertsDropdown from "./components/AlertsDropdown"
import {
  getLocalPersistSession,
  getTabCount,
  isTabActive,
  sessionIdleTime,
  sessionLogoutTimer
} from "./sessionMgmt"
import { SessionAlert } from "./components/SessionAlert"
import { isEmpty } from "@forcepoint/platform-utilityui"

export default function Header(props) {
  // ----------------------------------------------------------------------------------------
  // -------------------------------- Component States --------------------------------------
  // ----------------------------------------------------------------------------------------
  const pageTitle = window?.location?.pathname?.includes("editprofile")
  /**
   * To store app messages
   */
  const appMessages = coreStore.getState()?.applicationMessages

  /**
   * To store stateData
   */
  const stateData = coreStore.getState()

  /**
   * To set current dropdown
   */
  const [currentDropdown, setCurrentDropdown] = useState()

  /**
   * To set Change Password popup status
   */
  const [changePasswordPopupStatus, setChangePasswordPopupStatus] =
    useState<boolean>(false)

  /**
   * To set applications
   */
  const [applications, setApplications] = useState<any>({
    applications: [],
    platforms: [],
    coreApps: []
  })

  /**
   * To set user name
   */
  const [userName, setUserName] = useState<string>()

  /**
   * To set user name initials
   */
  const [userNameInitials, setUserNameInitials] = useState<string>()

  /**
   * To store and set alerts count
   */
  const [alertsCount, setAlertsCount] = useState<number>(0)

  /**
   * To show fone alert and set show fone alert
   */
  const [showFoneAlert, setShowFoneAlert] = useState(false)

  /**
   * To set alert props
   */
  const [alertProps, setAlertProps] = useState<any>()

  /**
   * To store settings menu list
   */
  const [settingsMenuList, setSettingsMenuList] = useState<any[]>([])

  /**
   * To set profile info modal status
   */
  const [profileInfoModalStatus, setProfileInfoModalStatus] =
    useState<boolean>(false)

  /**
   * To store and set alert permission
   */
  const [alertPermissions, setAlertPermissions] = useState<any>()

  /**
   * To store and set edit user profile permission
   */
  const [userPermission, setUserPermission] = useState<any>()

  /**
   * To store and set session persistance state
   */
  const [persistSession, setPersistSession] = useState(getLocalPersistSession())

  /**
   * To store and set session persistance state
   */
  const [showIdleTimeoutAlert, setShowIdleTimeoutAlert] = useState(false)

  /**
   * To store type of tenant-superadmin/mssp/distributor
   */
  const tenantType = sessionStorage.getItem("tenantType")
  const tenantName = JSON.parse(sessionStorage.getItem("tenantName"))

  /**
   * To store help url
   */
  const [helpUrl, setHelpUrl] = useState(null)

  // ----------------------------------------------------------------------------------------
  // -------------------------------- Component effects --------------------------------------
  // ----------------------------------------------------------------------------------------

  /**
   * To set applications
   */
  useEffect(() => {
    const appList = props.applications?.reduce(
      (a, b) => {
        if (b.showInSwitcher && b.isSPA && b.menus?.length > 0) {
          if (b.menuType === "application") {
            a.applications.push(b)
          } else if (b.menuType === "platform") {
            a.platforms.push(b)
          } else if (b.menuType === "none") {
            a.coreApps.push(b)
          }
        } else if (
          b.showInSwitcher &&
          (b.applicationType === "external" || b.openInIframe)
        ) {
          if (b.menuType === "application") {
            a.applications.push(b)
          } else if (b.menuType === "platform") {
            a.platforms.push(b)
          } else if (b.menuType === "none") {
            a.coreApps.push(b)
          }
        }
        return a
      },
      { applications: [], platforms: [], coreApps: [] }
    )
    setApplications(appList)
  }, [props.applications])

  /**
   * On Init effect
   */
  useEffect(() => {
    const coreStateSubscription = coreStore.subscribe(() => {
      const stateData = coreStore.getState()
      if (stateData?.activeApp?.modules?.uiConfig?.enableHelp) {
        if (!isEmpty(stateData.activeMenu?.supportUrls?.help)) {
          setHelpUrl(stateData.activeMenu?.supportUrls?.help)
        } else if (!isEmpty(stateData.activeApp?.supportUrls?.help)) {
          setHelpUrl(stateData.activeApp?.supportUrls?.help)
        } else {
          setHelpUrl(null)
        }
      }
      setSettingsMenuList(stateData.settingsMenu)
    })
    document.querySelector("body").addEventListener("click", toggleDropdown)
    return () => {
      if (coreStateSubscription) {
        coreStateSubscription()
      }
      document
        .querySelector("body")
        .removeEventListener("click", toggleDropdown)
    }
  }, [])

  /**
   * To manage user info related stuff
   */
  useEffect(() => {
    let userInfo: any = props?.userInfo
    if (sessionStorage.getItem("isEmulated")) {
      userInfo = JSON.parse(sessionStorage.getItem("oldUserInfo"))
    }
    if (userInfo?.first_name && userInfo?.last_name) {
      setUserName(userInfo?.first_name + " " + userInfo?.last_name)
      setUserNameInitials(
        userInfo?.first_name?.slice(0, 1).toUpperCase() +
          userInfo?.last_name?.slice(0, 1).toUpperCase()
      )
    } else if (userInfo?.first_name) {
      setUserName(userInfo?.first_name)
      setUserNameInitials(userInfo?.first_name?.slice(0, 2).toUpperCase())
    } else {
      setUserName(userInfo?.username)
      setUserNameInitials(userInfo?.username?.slice(0, 2).toUpperCase())
    }
    if (coreStore.getState().userInfo) {
      setAlertPermissions(calcScopePermission("idm.alert"))
      setUserPermission(calcScopePermission("idm.user"))
    }
  }, [props.userInfo])
  /**
   * To manage user info
   */
  useEffect(() => {
    if (tenantName) {
      setUserName(tenantName?.userName)
      setUserNameInitials(tenantName?.userInitials)
    }
  }, [tenantName])
  // ----------------------------------------------------------------------------------------
  // -------------------------------- Component Methods --------------------------------------
  // ----------------------------------------------------------------------------------------

  /**
   * To open menu
   * @param menuType
   */
  const openMenu = (menuType) => {
    setCurrentDropdown(menuType)
  }

  /**
   * To toggle dropdown
   * @param param0
   */
  const toggleDropdown = ({ target }) => {
    const nativeElement = document.querySelector(".shell-dropdown.show")
    if (
      nativeElement !== null &&
      !nativeElement.parentNode.contains(target as Node)
    ) {
      setCurrentDropdown(null)
    }
  }

  /**
   * To navigate to SPAs
   * @param url
   */
  const sPANavigate = (url) => {
    singleSpa.navigateToUrl(url)
  }

  /**
   * Current Dropdown effect
   */
  useEffect(() => {
    if (isEmpty(currentDropdown)) {
      document.querySelector("body").classList.remove("menu-open")
    } else {
      document.querySelector("body").classList.add("menu-open")
    }
  }, [currentDropdown])

  /**
   * To print switcher menus
   * @param menuName
   * @param menuList
   * @returns
   */
  const printSwitcherMenu = (menuName, menuList) => {
    const updatedMenuList = menuList?.filter(
      (a) =>
        !(
          props.activeApp?.applicationCode === a.applicationCode &&
          !props.settingsSidebar
        )
    )
    if (updatedMenuList?.length > 0) {
      return (
        <>
          {menuName !== "none" ? <li className="head-li">{menuName}</li> : null}

          {updatedMenuList?.map((a, b) => {
            const applicationUrl = APP_URL.endsWith(".eu")
              ? a.iframeURL
              : a.applicationURL
            return (
              <li key={b}>
                <a
                  id={`${a.applicationCode}_link`}
                  className="w-100"
                  onClick={() => {
                    if (!a.isSPA && !a.openInIframe) {
                      window.open(
                        applicationUrl,
                        a.openInNewTab ? "_blank" : "_self"
                      )
                    } else {
                      const url =
                        a.menus?.length > 0 ? a.menus[0].url : applicationUrl
                      sPANavigate(url)
                    }
                    setCurrentDropdown(null)
                  }}
                >
                  <span className="shell-app-list-acronym">
                    {a.applicationCode}
                  </span>
                  {a.applicationName}
                </a>
              </li>
            )
          })}
        </>
      )
    } else {
      return <></>
    }
  }

  /**
   * Show inactivity alert
   */
  const sessionInactivity = () => {
    setShowIdleTimeoutAlert(true)
  }

  /**
   * Show logout alert
   */
  const showLogoutAlert = () => {
    const alertData = {
      title: "Sign Out",
      text: appMessages.messages.header.CONFIRM_SIGN_OUT,
      positiveBtnText: "Sign Out",
      negativeBtnText: "No",
      showNegativeBtn: true,
      positiveBtnCallback: () => {
        coreStore.dispatch({ type: APP_LOGOUT })
        singleSpa.navigateToUrl("/logout")
        setShowFoneAlert(false)
      },
      negativeBtnCallback: () => {
        setShowFoneAlert(false)
      },
      type: "warning"
    }
    setAlertProps(alertData)
    setShowFoneAlert(true)
  }

  /**
   * Handle on idle function
   */
  const handleOnIdle = () => {
    const env = getEnvionment()
    if (stateData?.userToken && !persistSession && env !== "dev") {
      /** case 1: when none of the opened tab is active then logout the session*/
      if (getTabCount() > 0 && !isTabActive()) {
        // location.reload()
        sessionInactivity()
      } /** case 2: when only one tab is active*/ else if (
        !document.hidden &&
        isTabActive()
      ) {
        sessionInactivity()
      }
    }
  }

  useIdleTimer({
    onIdle: handleOnIdle,
    timeout:
      sessionIdleTime * 60 > sessionLogoutTimer * 60
        ? sessionIdleTime * 60 * 1000 - sessionLogoutTimer * 60 * 1000
        : sessionIdleTime * 60 * 1000
  })

  // ----------------------------------------------------------------------------------------
  // ------------------------------------ HTML Template -------------------------------------
  // ----------------------------------------------------------------------------------------

  return stateData?.userToken ? (
    <nav className="shell-app-header">
      {sessionStorage.getItem("isEmulated") && (
        <div className="view-context">{`Viewing as ${APP_TENANT.slice(
          0,
          -1
        )}`}</div>
      )}
      <div className="shell-app-logo">
        <div className="switcher">
          <a
            id="switcher_link"
            onClick={(e) => {
              e.preventDefault()
              openMenu("applications")
            }}
          >
            <img src="/assets/images/menu_dots_icon.svg" />
          </a>
          {applications?.coreApps &&
            applications?.applications &&
            applications?.applications &&
            [
              ...applications.coreApps,
              ...applications.applications,
              ...applications.platforms
            ]?.length > (props.settingsSidebar ? 0 : 1) && (
              <ul
                className={
                  "shell-dropdown " +
                  (currentDropdown === "applications" ? "show" : "")
                }
              >
                {printSwitcherMenu("none", applications?.coreApps)}
                {printSwitcherMenu("Applications", applications?.applications)}
                {printSwitcherMenu(
                  "Platform Services",
                  applications?.platforms
                )}
              </ul>
            )}
        </div>
        <h1 className="forceone-title">
          <span>
            <img src="/assets/images/f_logo_white.svg" />
          </span>
          <div
            className="pos-relative forcepoint-title"
            style={{ top: "-1px" }}
          >
            {pageTitle ? "Edit Profile" : props.name}
          </div>
        </h1>
        {props.pageTitle ? (
          <h1 className="forcepoint-subtitle">&nbsp;- {props.pageTitle}</h1>
        ) : null}
      </div>
      <ul className="shell-menu">
        {props.activeApp?.modules?.uiConfig?.enableNotifications &&
        (alertPermissions?.read || alertPermissions?.readOnly) ? (
          <li className="shell-dropdown-container notification-dropdown">
            <a
              id="alert_link"
              onClick={(e) => {
                e.preventDefault()
                openMenu("alert")
              }}
              className="pos-relative"
            >
              <NeoComponents.NeoIcon
                name="bell"
                size={22}
                data-always="true"
                data-dark-tip="Notifications"
              />
              {alertsCount > 0 ? (
                <span className="indicator">{alertsCount}</span>
              ) : null}
            </a>

            <ul
              style={{ maxHeight: "70vh", overflowY: "auto" }}
              className={
                "shell-dropdown notification-dropdown-menu " +
                (currentDropdown === "alert" ? "show" : "")
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0 20px"
                }}
              >
                <li>
                  <label className="li-notifications">Notifications</label>
                </li>
              </div>

              <AlertsDropdown
                userToken={stateData?.userToken}
                activeApp={props.activeApp}
                setAlertsCount={setAlertsCount}
              />
            </ul>
          </li>
        ) : null}
        {props.activeApp?.modules?.uiConfig?.enableSearch ? (
          <li>
            <a
              id="search_link"
              onClick={(e) => {
                e.preventDefault()
                // sPANavigate('/search');
              }}
            >
              <NeoComponents.NeoIcon name="search1" size={22} />
            </a>
          </li>
        ) : null}

        {settingsMenuList?.length > 0 ? (
          <li>
            <a
              id="settings_link"
              onClick={(e) => {
                e.preventDefault()
                sPANavigate(settingsMenuList[0].url)
              }}
            >
              <NeoComponents.NeoIcon
                name="settings-outline"
                size={22}
                data-always="true"
                data-dark-tip="Settings"
              />
            </a>
          </li>
        ) : null}

        {helpUrl !== null ? (
          <li>
            <a id="help_link" href={helpUrl} target="_blank">
              <NeoComponents.NeoIcon
                name="help"
                size={22}
                data-always="true"
                data-dark-tip="Help"
              />
            </a>
          </li>
        ) : null}
        <li className="vertical-line">
          <a onClick={(e) => e.preventDefault()}></a>
        </li>
        <li className="shell-dropdown-container user-dropdown">
          <a
            id="profile_dropdown_link"
            className={`${
              sessionStorage.getItem("isEmulated") ? "emulated" : ""
            }`}
            onClick={(e) => {
              e.preventDefault()
              openMenu("profile")
            }}
          >
            {userNameInitials}
          </a>
          <ul
            className={
              "shell-dropdown user-dropdown-menu " +
              (currentDropdown === "profile" ? "show" : "")
            }
          >
            <li
              id="profile_info_link"
              className="pdt-4 pdb-4"
              style={{
                cursor: "pointer"
              }}
              onClick={() => {
                setProfileInfoModalStatus(true)
              }}
            >
              <span className="li-span">{userNameInitials}</span>
              <span
                className={`li-span-text ${
                  !userName?.match(
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  )
                    ? "capitalize"
                    : "email"
                }`}
              >
                {userName}
              </span>
            </li>
            <li>
              <a
                id="edit_profile_link"
                onClick={(e) => {
                  e.preventDefault()
                  sessionStorage.setItem(
                    "editProfilePrevUrl",
                    window.location.pathname
                  )
                  coreStore.dispatch({
                    type: SET_ACTIVE_APP,
                    appName: null
                  })
                  singleSpa.navigateToUrl(`/editprofile`)
                }}
              >
                Edit Profile
              </a>
            </li>
            {sessionStorage.getItem("isEmulated") && (
              <li className="emulated-item">
                <a
                  id="return_to_link"
                  onClick={(e) => {
                    e.preventDefault()
                    sessionStorage.removeItem("isEmulated")
                    document.cookie = `${APP_TENANT}cookie.oidc.auth=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${""}`
                    document.cookie = `${APP_TENANT}cookie.oidc.refresh=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${""}`
                    window.location.href = "/opsc/organization"
                  }}
                >
                  {tenantType === "tenant"
                    ? "Return to Superadmin"
                    : tenantType === "distributor"
                      ? "Return to Distributor"
                      : tenantType === "mssp"
                        ? "Return to MSSP"
                        : "Return to Superadmin"}
                </a>
              </li>
            )}
            {props.userInfo?.idpCode === "local" &&
            !sessionStorage.getItem("isEmulated") ? (
              <li>
                <a
                  id="change_password_link"
                  onClick={(e) => {
                    e.preventDefault()
                    setChangePasswordPopupStatus(true)
                  }}
                >
                  Change Password
                </a>
              </li>
            ) : null}

            <li>
              <a
                id="sign_out_link"
                onClick={(e) => {
                  e.preventDefault()
                  showLogoutAlert()
                }}
              >
                Sign Out
              </a>
            </li>
          </ul>
        </li>
      </ul>
      {showIdleTimeoutAlert ? (
        <SessionAlert
          sessionLogoutTimer={sessionLogoutTimer}
          setShowIdleTimeoutAlert={setShowIdleTimeoutAlert}
          persistSession={persistSession}
          setPersistSession={setPersistSession}
        />
      ) : null}
      {showFoneAlert ? <FoneAlert alertData={alertProps} /> : null}
      {changePasswordPopupStatus ? (
        <ChangePasswordPopup
          changePasswordPopupStatus={changePasswordPopupStatus}
          setChangePasswordPopupStatus={setChangePasswordPopupStatus}
        />
      ) : null}
      {profileInfoModalStatus ? (
        <ProfileInfoModal
          profileInfoModalStatus={profileInfoModalStatus}
          setProfileInfoModalStatus={setProfileInfoModalStatus}
        />
      ) : null}
    </nav>
  ) : (
    <nav className="shell-app-header">
      <div className="shell-app-logo">
        <div className="switcher">
          <a>
            <img src="/assets/images/menu_dots_icon.svg" />
          </a>
        </div>

        <h1 className="forceone-title">
          <span>
            <img src="/assets/images/f_logo_white.svg" />
          </span>
        </h1>
      </div>
    </nav>
  )
}
