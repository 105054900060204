import { useRef } from "react"
import { isValidOTPDigit } from "../userprofile.model"

const OTPInputBox = ({ passcode, setPassCode }) => {
  const otpFields = Array.from({ length: 6 }, () => useRef(null))

  const onOTPKeyDown = (event, index) => {
    const { value } = event.target
    if (value.length === 1) {
      if (index < otpFields.length - 1) {
        otpFields[index + 1].current.focus()
        otpFields[index + 1].current.select()
      }
    } else if (value.length === 0) {
      if (index > 0) {
        otpFields[index - 1].current.focus()
        otpFields[index - 1].current.select()
      }
    }
  }
  return (
    <>
      {otpFields &&
        [...otpFields].map((ref, index) => {
          return (
            <input
              size={1}
              autoComplete="off"
              id={"otp-box-" + index}
              key={"otp-box-" + index}
              ref={ref}
              className={`otp-input-box ${!!passcode[index] && !isValidOTPDigit(passcode[index]) ? "invalid" : ""}`}
              type="text"
              value={"" + passcode[index]}
              onChange={(event) => {
                onOTPKeyDown(event, index)
                const otp = [...passcode]
                otp[index] = event.target.value
                setPassCode(otp)
              }}
              maxLength={1}
            />
          )
        })}
    </>
  )
}
export default OTPInputBox
