import { useState } from "react"
import { coreStore } from "@forcepoint/platform-coreui"
import { useEffect } from "react"
import * as singleSpa from "single-spa"
import { NeoComponents } from "@forcepoint/platform-utilityui"

const SettingsSidebar = (props) => {
  /**
   * To store settings groups
   */
  const [settingsGroups, setSettingsGroups] = useState<any[]>([])

  /**
   * To store location
   */
  const [location, setLocation] = useState("")

  useEffect(() => {
    const stateData = coreStore.getState()
    const settingsMenu = stateData.settingsMenu
    if (settingsMenu.length > 0) {
      const menuGroups = settingsMenu.reduce((a, b, c, d) => {
        const findIndex = a.findIndex((a) => a.name === b.group)
        if (findIndex === -1) {
          a.push({
            id: a.length + 1,
            name: b.group,
            isOpen: false,
            settings: [{ ...b }]
          })
        } else {
          a[findIndex].settings.push({ ...b })
        }
        if (b.url === window.location.pathname) {
          a[a.length - 1].isOpen = true
        }
        return a
      }, [])
      setSettingsGroups(menuGroups)
    }
  }, [])

  useEffect(() => {
    setLocation(window.location.pathname)
  }, [window.location.pathname])

  /**
   * UI Template
   */
  return (
    <div className="settings-sidebar shadow-sm">
      {settingsGroups?.map((a, b) => (
        <div className="settings-section" key={b}>
          <div
            className="settings-section-header"
            style={{ backgroundColor: "#eee" }}
          >
            <h6 className="settings-section-title">{a.name}</h6>
          </div>
          <div className="settings-options">
            {a.settings.map((c, d) => (
              <a
                id={c.name}
                key={c.url}
                className={`settings-info ${c.url === location ? "active" : ""}`}
                onClick={() => {
                  singleSpa.navigateToUrl(c.url)
                }}
              >
                <NeoComponents.NeoIcon name={c.iconUrl} />
                <span className="span-ps-3">{c.name}</span>
              </a>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default SettingsSidebar
