import { coreStore } from "@forcepoint/platform-coreui"
import { NeoPopup, NeoComponents } from "@forcepoint/platform-utilityui"
import { useEffect, useState } from "react"
import { UserInfo } from "../../../navigation.model"

const ProfileInfoModal = (props) => {
  const [userinfo, setUserInfo] = useState<UserInfo>()

  useEffect(() => {
    const stateData = coreStore.getState()
    let userInfo = { ...stateData.userInfo }
    if (sessionStorage.getItem("isEmulated")) {
      userInfo = JSON.parse(sessionStorage.getItem("oldUserInfo"))
    }
    userInfo.ext.tenantId = userInfo?.ext?.tenantId.map((a, b) => {
      return { ...a, isClicked: false, key: b + 1 }
    })
    setUserInfo(userInfo)
  }, [])

  return (
    <NeoPopup
      modalOpenStatus={props.profileInfoModalStatus}
      modalTitle={"Profile Information"}
      setOpenModalStatus={props.setProfileInfoModalStatus}
      popupClass={"fone-modal-md"}
    >
      <div className="change-password profile-info-modal">
        <div className="widget-popup-container">
          <div style={{paddingTop:'0.5rem',paddingBottom:'0.5rem'}}>
            <div style={{display:'flex',justifyContent:'space-between'}}>
              <div className="maxw-50">
                <div className="name-label">
                  Name:
                  <span style={{ wordBreak: "break-word",fontWeight:'bold' }}>
                    {userinfo?.first_name} {userinfo?.last_name}
                  </span>
                </div>
              </div>
              <div className="maxw-50">
                <div className="name-label">
                  Username:
                  <span style={{ wordBreak: "break-word",fontWeight:'bold' }}>
                    {userinfo?.username}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="reference-div">
            <div className="reference-div-header">
              <label className="reference-div-label">Reference IDs</label>
            </div>
            {userinfo?.ext?.tenantId?.map((a, b) => {
              return (
                <>
                  <div
                    className="reference-div-userinfo"
                    key={a.key}
                  >
                    <div
                      style={{
                        textTransform: "uppercase",
                        maxWidth: "100px",
                        flex: 1
                      }}
                    >
                      {a.hint}
                    </div>
                    <div style={{ flex: 1 }}>{a.id}</div>
                    <div
                      style={{ maxWidth: "45px", flex: 1, cursor: "pointer",paddingLeft:'0.5rem' }}
                      onClick={() => {
                        navigator.clipboard.writeText(a.id)
                        const userInfo = { ...userinfo }
                        userInfo.ext.tenantId = userInfo?.ext?.tenantId.map(
                          (c, d) => {
                            return { ...c, isClicked: false, key: d + 1 }
                          }
                        )
                        userInfo.ext.tenantId[b].isClicked = true
                        setUserInfo(userInfo)
                      }}
                    >
                      {a.isClicked ? (
                        <span style={{ color: "#00AF9A", fontSize: "11px" }}>
                          Copied
                        </span>
                      ) : (
                        <NeoComponents.NeoIcon
                          data-always="true"
                          data-dark-tip="Click to copy"
                          name="copy1"
                        />
                      )}
                    </div>
                  </div>
                </>
              )
            })}
          </div>
        </div>
      </div>
    </NeoPopup>
  )
}
export default ProfileInfoModal
